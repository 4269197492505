<template>
  <overlay-colored-popup class="light-blue balance-money-popup" :back-action="onBack" :dark-text="true">
    <template>
      <div class="margin-top-medium-large">
        <card>
          <div class="card-header">קרן כספית</div>
          <template v-slot:footer>
            <div class="body-text">
              <div class="bold margin-top-medium-large margin-bottom-small">
                קרנות כספיות הוגדרו ע״י נגיד בנק ישראל כ<span class="accent-text">״בטוחות יותר ומעניקות תשואה גבוהה יותר מהפיקדונות״</span>
              </div>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>סיכון נמוך</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>אפשר למשוך את הכסף <span class="bold">בכל יום</span></template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text><span class="bold">דמי ניהול נמוכים</span>, ללא עמלת רכישה</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>ההשקעה נעשית דרך הבנק או חברת השקעות</template>
              </icon-and-text>
              <riseup-button
                class="margin-top-medium-large margin-bottom-large"
                variant="secondary"
                title="אני רוצה לשמוע עוד"
                :action="moneyFundClicked"
                size="slim"
                :bold="true"/>
            </div>
          </template>
        </card>
        <card class="margin-top-large">
          <div class="card-header">פק״מ בבנק</div>
          <template v-slot:footer>
            <div class="body-text margin-top-medium">
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>ללא סיכון</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>כדי לקבל תשואה גבוהה, צריך <span class="bold">לנעול את הכסף לתקופה של מעל שנה</span></template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text><span class="bold">ללא דמי ניהול</span></template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>החיסכון נעשה דרך הבנק</template>
              </icon-and-text>
              <riseup-button
                class="margin-top-medium-large margin-bottom-large"
                variant="secondary"
                title="אני רוצה לשמוע עוד"
                :action="pakamClicked"
                size="slim"
                :bold="true"/>
            </div>
          </template>
        </card>
        <card class="margin-top-large margin-bottom-medium">
          <div class="card-header">Box ב־PayBox</div>
          <template v-slot:footer>
            <div class="body-text">
              <div class="bold margin-top-medium-large margin-bottom-small">
                לפתוח Box, להעביר את הכסף בקלות, ולשלם ישירות ממנו בחנויות
              </div>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>ללא סיכון</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text><span class="bold">הכסף תמיד זמין</span></template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text><span class="bold">ריבית קבועה של 3%</span>, ללקוחות האשראי הדיגיטלי של פייבוקס</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>עד 20,000 ₪</template>
              </icon-and-text>
              <icon-and-text v-bind="iconAndTextProps">
                <template v-slot:text>אין דמי ניהול</template>
              </icon-and-text>
              <riseup-button
                class="margin-top-medium-large margin-bottom-large"
                variant="secondary"
                title="אני רוצה לשמוע עוד"
                :action="boxClicked"
                size="slim"
                :bold="true"/>
            </div>
          </template>
        </card>
      </div>
    </template>
    <template v-slot:footer v-if="!hideSnooze">
      <div class="centered">
        <a class="link-button" @click="remindMeLaterClicked">
          מעניין, תזכירו לי אח"כ
        </a>
      </div>
    </template>
  </overlay-colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'BalanceMoneyStep3',
  components: {
    OverlayColoredPopup: BaseUI.OverlayColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    IconAndText: BaseUI.IconAndText,
    Card: BaseUI.Card,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onMoneyFundClicked: {
      type: Function,
      required: true,
    },
    onPakamClicked: {
      type: Function,
      required: true,
    },
    onBoxClicked: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
    hideSnooze: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      iconAndTextProps: {
        iconSize: '16px',
        fillIcon: true,
        class: 'balance-money-icon-and-text',
        iconFontName: 'check_circle',
        iconColor: BaseUI.Colors.riseupBlue,
      },
    };
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  methods: {
    moneyFundClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedMoneyFund');
      this.onMoneyFundClicked();
    },
    pakamClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedPakam');
      this.onPakamClicked();
    },
    boxClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedBox');
      this.onBoxClicked();
    },
    remindMeLaterClicked() {
      this.onDismiss(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
